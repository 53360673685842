
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "BillingConceptCodesSelect",
  props: {
    billing_concept_code_id: {
      type: Number,
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    custom_class: {
      type: String,
      default: "custom-select w-100 p-0",
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    datas: {
      type: Array,
      default: [] as any,
    },
  },
  emits: ["update:billing_concept_code_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const billingConceptCodesData = computed(
      () => store.getters.BillingConceptCodes
    );
    const inputElements = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isElementRequired = (value) => {
      if (props.required && !value) {
        return t("rbillingconceptcode");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "concept",
      isElementRequired
    );

    const getElements = () => {
      if (billingConceptCodesData.value.length <= 0) {
        store.commit("setLoading", false);
        ApiService.query("/api/billing_concept_codes/lists", {
          params: {
            name: "",
            per_page: 5,
          },
        }).then(({ data }) => {
          store.commit("setBillingConceptCodes", data.billing_concept_codes);
          // inputElements.value.list = data.billing_concept_codes;
          inputElements.value.options = data.billing_concept_codes;
        });
      } else {
        // inputElements.value.list = billingConceptCodesData.value;
        inputElements.value.options = billingConceptCodesData.value;
      }
      const index = inputElements.value.options.findIndex(
        (x) => x.name === "Todos"
      );
      if (props.multiselect) {
        if (index === -1) {
          inputElements.value.options.splice(0, 0, {
            id: 0,
            name: "Todos",
          });
        }
      } else if (index !== -1) {
        inputElements.value.options.splice(0, 1);
      }
    };
    const selectElements = (query) => {
      if (query !== "") {
        store.commit("setLoading", false);
        ApiService.query(`/api/billing_concept_codes/lists`, {
          params: {
            name: query,
            per_page: 5,
          },
        }).then(({ data }) => {
          data.billing_concept_codes.forEach((item) => {
            store.commit("addBillingConceptCodes", item);
          });
          // inputElements.value.list = billingConceptCodesData.value;
          inputElements.value.options = data.billing_concept_codes;
        });
      } else {
        inputElements.value.options = billingConceptCodesData.value;
      }
    };

    watch(
      () => props.billing_concept_code_id,
      (first) => {
        element_id.value = first;
      }
    );

    onMounted(() => {
      setTimeout(function () {
        element_id.value = props.billing_concept_code_id;
        inputElements.value.options = props.datas;
        // inputElements.value.list = props.datas;
      }, 3000);
    });

    return {
      element_id,
      inputElements,
      errorMessage,
      selectElements,
      getElements,
    };
  },
};
